<template>
  <main-body-section>
    <div class="-m-anti-card" id="map" ref="map" />
    <div id="menu"></div>

    <geocode-search @selectedGeocode="geocodeFind"></geocode-search>

    <div class="calculation-box y-center-g-5" v-if="rounded_area">
      <CIcon name="cilInfo"></CIcon>
      <span>مساحت: {{ rounded_area }} هکتار</span>
    </div>
    <div class="absolute-center p-0 m-0 animate__animated animate__pulse" v-if="isSelectedArea">
      <div id="mobileMenu" class="d-md-none">
        <button
          class="p-mx-1"
          style="width: 5rem; height: 5rem"
          @click="goNextStep()"
        >
          <CIcon name="cilArrowThickToRight" width="2.2rem" />
        </button>
      </div>
      <div class="y-center-g-5">
        <CButton
          color="secondary"
          class="p-mx-1 maptooltip"
          @click="goNextStep()"
        >
          <CIcon name="cilArrowThickToRight"/>
          <span class="mr-2 text-nowrap">تکمیل اطلاعات زمین</span>
        </CButton>
      </div>
    </div>
  </main-body-section>
</template>

<script>
import {mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import {ZoomControl} from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import polyGonStructure from "../../store/data/polygon.json";
import GeocodeSearch from "../../components/map/GeocodeSearch.vue";
import MainBodySection from "../../components/mainBodySection.vue";

export default {
  name: "farmAddMap",
  components: {
    MainBodySection,
    // SwitchMap,
    GeocodeSearch,
  },
  data() {
    return {
      rounded_area: null,
      mapCenter: null,

      isSatelite: true,
      draw: null,
      map: null,
      isAddSource: false,
      isSelectedArea: false,
      coordinates: [],
    };
  },

  methods: {
    ...mapActions("farm", ["setAreaString"]),

    goNextStep() {
      if (this.setPolygon()) {
        this.$router.push({ name: "ثبت جزئیات زمین" });
      }
    },
    setPolygon() {
      const newArray = [this.coordinates];
      polyGonStructure.geometry.coordinates = newArray;
      var features = turf.points(this.coordinates);
      var center = turf.center(features);
      const newCenterString = JSON.stringify(center, null, 0);
      const newAreaString = JSON.stringify(polyGonStructure, null, 0);
      this.setAreaString({
        areaString: newAreaString,
        coordinationString: newCenterString,
        farmImage: this.map.getCanvas().toDataURL(),
      });
      return true;
    },
    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },
    consolArea() {
      const data = this.draw.getAll();
      if (data.features.length > 0) {
        let area = turf.area(data.features[0].geometry);
        this.mapCenter = turf.centerOfMass(
          data.features[0].geometry
        ).geometry.coordinates;
        // restrict to area to 2 decimal points
        this.rounded_area = Intl.NumberFormat("fa").format(
          (area / 10000).toFixed(2)
        );
        this.isSelectedArea = true;
        this.coordinates = data.features[0].geometry.coordinates[0];
      } else {
        this.mapCenter = null;
        this.rounded_area = null;
        this.isSelectedArea = false;
        this.coordinates = [];
      }
    },
    switchLayer(layer) {
      this.map.setStyle("mapbox://styles/" + layer);
      this.isSatelite = !this.isSatelite;
    },
    addAreaTitle() {
      let areaTitle = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              description: this.rounded_area + "هکتار",
              icon: "theatre-15",
            },
            geometry: {
              type: "Point",
              coordinates: this.mapCenter,
            },
          },
        ],
      };
      if (this.isAddSource) {
        this.map.getSource("areaTitle").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                description: this.rounded_area + "هکتار",
                icon: "theatre-15",
              },
              geometry: {
                type: "Point",
                coordinates: this.mapCenter,
              },
            },
          ],
        });
      } else {
        this.map.addSource("areaTitle", {
          type: "geojson",
          data: areaTitle,
        });
        this.isAddSource = true;
      }
    },
  },

  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {//loaded
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh",
      // center: [59.55679711007316, 36.316138357670575], // starting position [lng, lat]
      center: [53.12609431323281, 33.59332186046887],
      zoom: 5, // starting zoom
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });

    // this.map.addControl(
    // 	new mapboxgl.GeolocateControl({
    // 		positionOptions: {
    // 			enableHighAccuracy: false,
    // 		},
    // 		trackUserLocation: false,
    // 	})
    // );
    // var nav = new mapboxgl.NavigationControl();
    // this.map.addControl(nav, "bottom-left");
    // this.map.addControl(
    // 	new MapboxGeocoder({
    // 		accessToken: mapboxgl.accessToken,
    // 		mapboxgl: mapboxgl,
    // 		language: "fa-IR",
    // 	})
    // );
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true,
      },
      defaultMode: "draw_polygon",
    });
    //  this.draw = new MapboxDraw();
    this.map.addControl(this.draw, "bottom-right");

    this.map.addControl(new ZoomControl(), "bottom-left");

    // Map#addControl takes an optional second argument to set the position of the control.
    // If no position is specified the control defaults to `top-right`. See the docs
    // for more details: https://docs.mapbox.com/mapbox-gl-js/api/#map#addcontrol

    this.map.on("load", () => {
      // TODO: Here we want to load a layer
      // TODO: Here we want to load/setup the popup
    });

    this.map.on("draw.create", this.consolArea);
    this.map.on("draw.create", this.addAreaTitle);
    this.map.on("draw.delete", this.consolArea);
    this.map.on("draw.update", this.consolArea);
  },
};
</script>

<style scoped>

#map {
  position: absolute;
  inset: 0;
}

#menu {
  position: absolute;
  left: 50%;
  /* background: #efefef; */
  font-family: "Open Sans", sans-serif;
  width: 15rem;
  margin-left: -7.5rem;
}
.calculation-box {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 57%;
  top: 0;
  border-radius: var(--border-radius);
  padding: 10px;
}

</style>

<style scoped>
#mobileMenu {
  height: 2rem;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  background-color: transparent;
  text-align: center;
  padding: 15px 15px 0;
}
</style>
